/* vietnamese */
@font-face {
  font-family: '__Lexend_86777a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/630e0b819503bca7-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Lexend_86777a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6a1a9d784472a388-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Lexend_86777a';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/84455f2b5a591033-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Lexend_Fallback_86777a';src: local("Arial");ascent-override: 90.98%;descent-override: 22.74%;line-gap-override: 0.00%;size-adjust: 109.91%
}.__className_86777a {font-family: '__Lexend_86777a', '__Lexend_Fallback_86777a';font-style: normal
}

/*
100	Thin (Hairline)
200	Extra Light (Ultra Light)
300	Light
400	Normal (Regular)
500	Medium
600	Semi Bold (Demi Bold)
700	Bold
800	Extra Bold (Ultra Bold)
900	Black (Heavy)
950	Extra Black (Ultra Black)
*/


@font-face {
  font-family: 'Rajdhani';
  font-weight: 300;
  src: url(/_next/static/media/Rajdhani-Light.f782e397.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  font-weight: 400;
  src: url(/_next/static/media/Rajdhani-Regular.1942d209.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  font-weight: 500;
  src: url(/_next/static/media/Rajdhani-Medium.eb6b8dee.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  font-weight: 600;
  src: url(/_next/static/media/Rajdhani-SemiBold.de7b7962.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  font-weight: 700;
  src: url(/_next/static/media/Rajdhani-Bold.de9167c4.woff2);
  font-display: swap;
}


@font-face {
  font-family: 'Averta';
  font-weight: 300;
  src: url(/_next/static/media/Averta-Light.273abfec.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Averta';
  font-weight: 400;
  src: url(/_next/static/media/Averta-Regular.927194f8.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Averta';
  font-weight: 500;
  src: url(/_next/static/media/Averta-Regular.927194f8.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Averta';
  font-weight: 700;
  src: url(/_next/static/media/Averta-Bold.7c9703c9.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Greycliff Oblique';
  font-weight: 700;
  src: url(/_next/static/media/GreycliffCF-BoldOblique.c08d9aa3.woff);
  font-display: swap;
}

